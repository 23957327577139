import {actions} from 'bkn-auth';
import * as _get from 'lodash.get';
import {createSelector} from 'reselect';
import {$call} from 'utility-types';

import {Dispatch, RootState} from 'config/redux';

import {getUserRoles} from '../HasRole';

export const mapStateToProps = (state: RootState) => ({
  userRoles: getUserRoles(state),
  isAdmin: getUserRoles(state).includes('admin'),
});

const mapStateToPropsNope = $call(mapStateToProps);
export type MapStateToProps = typeof mapStateToPropsNope;

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(actions.logout()),
});

const mapDispatchToPropsNope = $call(mapDispatchToProps);
export type MapDispatchToProps = typeof mapDispatchToPropsNope;
