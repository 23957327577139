import {spawn} from 'redux-saga/effects';

import {safe} from 'utils';

import {appSaga} from './global';
import {geofenceSaga} from './modules/geofence';
import {geofenceCollectionSaga} from './modules/geofenceCollection';

export const rootSaga = safe(function*() {
  const sagas = [appSaga, geofenceSaga, geofenceCollectionSaga];

  yield sagas.map((saga) => spawn(saga));
});
