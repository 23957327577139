import {CSSProperties} from 'react';

export const styles = (theme) => ({
  actionMenuItemText: {
    fontWeight: 500,
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.74)',
    minWidth: 70,
    display: 'flex',
  } as CSSProperties,
});
