import {CSSProperties} from 'react';
import {Theme} from 'material-ui';

export const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
  } as CSSProperties,
  columnToolBar: {
    minHeight: 50,
  } as CSSProperties,
  columnToolBarTitle: {
    fontWeight: 'bold',
    flex: 1,
  } as CSSProperties,
  columnContent: {
    backgroundColor: '#fff',
    height: 'calc(100vh - 104px)',
    minWidth: 300,
    overflowY: 'scroll',
    borderRight: '1px solid #eee',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  } as CSSProperties,
  columnMap: {
    flex: 1,
  } as CSSProperties,
  columnContentMap: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  } as CSSProperties,
  chevronIcon: {
    right: '1rem',
  } as CSSProperties,
  selectedItem: {
    backgroundColor: '#EBEBEB',
    pointerEvents: 'none',
  } as CSSProperties,
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  } as CSSProperties,
  button: {
    color: theme.palette.primary.main,
  },
});
