import * as React from 'react';
import {connect} from 'react-redux';
import {authorize} from 'components/common/hoc';
import {page} from 'components/hoc';

import {CollectionListContainer} from './components';
import {
  mapDispatchToProps,
  MapDispatchToProps,
  mapStateToProps,
  MapStateToProps,
} from './selectors';

export type CustomProps = MapStateToProps & MapDispatchToProps;

@authorize({roles: ['admin']})
@page({title: 'Custom'})
class CustomContainerComponent extends React.Component<CustomProps> {
  componentDidMount() {
    this.props.fetchCollections();
  }

  componentWillReceiveProps(nextProps: CustomProps) {
    if (
      nextProps.selectedCollection &&
      this.props.selectedCollection !== nextProps.selectedCollection
    ) {
      this.props.fetchGeofences();
    }
  }

  render() {
    return (
      <CollectionListContainer
        selectedCollection={this.props.selectedCollection}
        selectedGeofence={this.props.selectedGeofence}
        collections={this.props.collections}
        geofences={this.props.geofences}
        addCollection={this.props.addCollection}
        editCollection={this.props.editCollection}
        removeCollection={this.props.removeCollection}
        addGeofence={this.props.addGeofence}
        exportCollection={this.props.exportCollection}
        handleCollectionClick={this.handleCollectionClick}
        handleGeofenceClick={this.handleGeofenceClick}
      />
    );
  }

  handleCollectionClick = (collectionId: string) => {
    this.props.setSelectedCollection(collectionId);

    if (collectionId) {
      this.props.fetchGeofences();
    }
  };
  handleGeofenceClick = (geofenceId: string) => {
    this.props.setSelectedGeofence(geofenceId);
  };
}

export const CustomContainer = connect(mapStateToProps, mapDispatchToProps)(
  CustomContainerComponent,
);
