import * as React from 'react';
import {CSSProperties, HTMLProps, MouseEvent, PureComponent, SFC} from 'react';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {authenticationRequired, AuthorizeProps} from 'bkn-auth';
import {Layout as BknLayout, Page as BknPage, PageBody} from 'bkn-ui';
import {CircularProgress} from 'material-ui';
import CloseIcon from 'material-ui-icons/Close';
import Snackbar, {SnackbarProps} from 'material-ui/Snackbar';

import {ServiceNavigationMenu} from 'components/common/ServiceNavigationMenu';
import {environment} from 'config';

import {DrawerContent} from './DrawerContent';
import {
  mapDispatchToProps,
  MapDispatchToProps,
  mapStateToProps,
  MapStateToProps,
} from './selectors';
import {UserMenu} from './UserMenu';

interface Props extends MapStateToProps, MapDispatchToProps, AuthorizeProps {}

interface State {
  drawerOpen: boolean;
}

@authenticationRequired
class LayoutComponent extends PureComponent<Props> {
  static defaultProps: Partial<Props> = {};

  state: State = {
    drawerOpen: false,
  };

  render() {
    const {app} = environment.settings;
    const {
      pageTitle,
      hasServiceWorkerUpdate,
      hasServiceWorkerQuotaExceeded,
      message,
      messageOpened,
      wontRefreshApp,
      onRefresh,
      ...otherProps
    } = this.props;

    const iconAction = {
      loading: (
        <CircularProgress style={{color: 'white'}} color="inherit" size={24} thickness={5} />
      ),
    }[message.action];

    return (
      <BknLayout
        mainNavNoMenuButton={true}
        drawerContent={this.renderAppDrawerContent()}
        drawerTitle={app.name}
        mainNavLeftTitleContent={this.renderArrowMenu()}
        mainNavTitle={app.name}
        mainNavElementRight={this.renderAccountMenu()}
        drawerOpen={this.state.drawerOpen}
        onDrawerOpen={this.handleDrawerOpen}
        onDrawerClose={this.handleDrawerClose}
        {...otherProps}
      >
        <Helmet>
          <title>{`${pageTitle} | ${app.name}`}</title>
        </Helmet>
        <BknPage>
          <PageBody fullscreen>{this.props.children}</PageBody>
        </BknPage>

        <CustomSnackbar
          open={messageOpened}
          message={<span>{message.title}</span>}
          action={iconAction}
          onClose={this.onRequestCloseHandler}
        />
        <CustomSnackbar
          open={hasServiceWorkerUpdate && !messageOpened}
          message={<span>A new version is available</span>}
          action={<RefreshSnackbar onClose={wontRefreshApp} onRefresh={onRefresh} />}
          onClose={this.onRequestCloseHandler}
        />
        <CustomSnackbar
          open={hasServiceWorkerQuotaExceeded && !messageOpened}
          message={<span>Service worker quota exceeded</span>}
          action={<RefreshSnackbar onClose={wontRefreshApp} onRefresh={onRefresh} />}
          onClose={this.onRequestCloseHandler}
        />
      </BknLayout>
    );
  }

  renderArrowMenu = () => {
    return <ServiceNavigationMenu />;
  };

  renderAppDrawerContent = () => (
    <DrawerContent activeNavLink={this.props.activeNavLink} onClickLink={this.handleLinkClick} />
  );

  handleLinkClick = () => {
    this.handleDrawerClose();
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false});
    this.forceUpdate();
  };

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true});
  };

  onRequestCloseHandler = () => {
    this.props.hideMessage();
  };

  renderAccountMenu = () => {
    const {user} = this.props;

    return <UserMenu loading={false} user={user} onClickLogoutButton={this.logout} />;
  };

  logout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.logout();
  };
}

interface RefreshSnackbarProps {
  onRefresh: () => void;
  onClose: () => void;
}

const RefreshSnackbar = (props: RefreshSnackbarProps) => (
  <div style={styles.refreshSnackbarContainer}>
    <span style={styles.refreshSnackbarSpan} onClick={props.onRefresh}>
      REFRESH
    </span>
    <CloseIcon style={styles.refreshSnackbarIcon} onClick={props.onClose} />
  </div>
);

const CustomSnackbar = ({open, message, action, onClose}: SnackbarProps) => (
  <Snackbar
    open={open}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    message={<span>{message}</span>}
    action={action}
    autoHideDuration={action ? undefined : 6000}
    onClose={onClose}
  />
);

const styles = {
  refreshSnackbarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 7,
  } as CSSProperties,
  refreshSnackbarSpan: {
    color: 'rgb(226, 188, 255)',
    cursor: 'pointer',
    marginRight: 10,
    fontSize: 12,
    fontWeight: 'bold',
  } as CSSProperties,
  refreshSnackbarIcon: {
    width: 18,
    height: 18,
    cursor: 'pointer',
  } as CSSProperties,
};

export const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
