import {actions as bknAuthActions, sagas as bknAuthSagas} from 'bkn-auth';
import {fork, takeLatest} from 'redux-saga/effects';

import {safe} from 'utils';
import {getAccountLink, getDomain} from 'utils/url';

import {redirectToHomePage} from './routing';
import {serviceWorkerSaga} from './serviceWorker';

//  Helpers
//  Actions
//  Sagas

export const appSaga = safe(function*() {
  yield fork(bknAuthSagas.authenticationFlow, getDomain(), getAccountLink());
  yield fork(serviceWorkerSaga);

  yield takeLatest(bknAuthActions.redirectNotAuthorizedUser, redirectToHomePage);
});
