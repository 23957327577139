import axios from 'axios';
import {AxiosInstance, AxiosPromise} from 'axios';

interface Options {
  apiUrl: string;
  baseUrl: string;
  token: string;
}

export abstract class RestService<T> {
  private options: Options;
  protected axiosInstance: Readonly<AxiosInstance>;

  constructor(options: Options) {
    this.options = options;

    this.axiosInstance = axios.create({
      baseURL: `${options.baseUrl}${options.apiUrl}`,
      headers: {
        Authorization: `Bearer ${options.token}`,
        'X-Tenant-ID': 'ofm',
      },
    });
  }

  public async create(data: {name: string}) {
    const res = await this.axiosInstance
      .post('/', data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response || error;
      });

    if (res.status === 400) {
      const parsedResponse = JSON.parse(res.data.response);
      throw new Error(parsedResponse.error.type);
    } else if (res.status !== 200) {
      throw new Error(res);
    }
    return res.data;
  }

  public async find(query: Object = {}) {
    const res = await this.axiosInstance.post('/search', query);
    return res.data;
  }

  public async get(id: string) {
    const res = await this.axiosInstance.get(`/${id}`);
    return res.data;
  }

  public async update(id: string | number, data: any) {
    const res = await this.axiosInstance.patch(`/${id}`, data);
    return res.data;
  }

  public async remove(id: string | number) {
    const res = await this.axiosInstance.delete(`/${id}`);
    return res.data;
  }
}
