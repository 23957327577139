import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {actions, Permissions, User} from 'bkn-auth';
import * as _get from 'lodash.get';
import * as _isEmpty from 'lodash.isempty';

import {
  mapDispatchToProps,
  MapDispatchToProps,
  mapStateToProps,
  MapStateToProps,
} from './selectors';

interface Props extends MapStateToProps, MapDispatchToProps {}

export interface AuthorizeProps {
  userRoles: string[];
}

export interface AuthorizeOptions {
  roles?: string[];
}

export const authorize = (options?: AuthorizeOptions) => (WrappedComponent) => {
  return connect<MapStateToProps, MapDispatchToProps, null>(mapStateToProps, mapDispatchToProps)(
    authComponent(WrappedComponent, options),
  ) as any;
};

const authComponent = (WrappedComponent: any, options?: AuthorizeOptions) =>
  class Authorize extends Component<Props, {}> {
    componentDidMount() {
      const {logout, isAdmin} = this.props;

      if (!isAdmin && !this.isUserAuthorized()) {
        logout();
      }
    }

    render() {
      const {isAdmin} = this.props;
      return (isAdmin || this.isUserAuthorized()) && <WrappedComponent {...this.props} />;
    }

    isUserAuthorized = () => {
      const everyoneAuthorized = _isEmpty(options);

      if (everyoneAuthorized) {
        return true;
      }
      const {userRoles} = this.props;

      const allowedRoles = _get(options, 'roles', []) as string[];

      return allowedRoles.every(
        (r) => (r.includes(':') ? userRoles.includes(r) : userRoles.some((u) => u.includes(r))),
      );
    };
  };
