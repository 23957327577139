import * as React from 'react';
import * as _get from 'lodash.get';
import ChevronRightIcon from 'material-ui-icons/ChevronRight';
import CommentIcon from 'material-ui-icons/Comment';
import MenuIcon from 'material-ui-icons/Menu';
import MoreVertIcon from 'material-ui-icons/MoreVert';
import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import List, {ListItem, ListItemSecondaryAction, ListItemText} from 'material-ui/List';
import Menu, {MenuItem} from 'material-ui/Menu';
import {CircularProgress} from 'material-ui/Progress';
import {StyledComponentProps, withStyles} from 'material-ui/styles';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';

import {GeofenceContainer} from '../Geofence';
import {OptionsContainer} from '../Options';
import {styles} from './CollectionList.style';

const CollectionListViewComponent = (props) => {
  const selectedCollectionName = _get(props.collections, `${props.selectedCollection}.name`);

  return (
    <div className={props.classes.root}>
      <div className={props.classes.column} style={{maxWidth: 300}}>
        <ListHeader title="Collections" classes={props.classes}>
          <OptionsContainer
            creating={props.creatingCollection}
            createEntity={'Collection'}
            onSubmitCreate={props.onCreateCollection}
            selectedCollection={props.selectedCollection}
            selectedGeofence={props.selectedGeofence}
            onClose={props.onCloseCollectionModal}
          />
        </ListHeader>
        <ListBody entity="collections" {...props}>
          <ListButton
            name="Add Collection"
            classes={props.classes}
            onClick={props.onAddCollectionClick}
          />
          <CollectionsBody {...props} />
        </ListBody>
      </div>
      <div className={props.classes.column} style={{maxWidth: 300}}>
        <ListHeader title="Geofences" classes={props.classes}>
          {Object.keys(props.collections || {}).length > 0 && (
            <OptionsContainer
              creating={props.creatingGeofence}
              createEntity={'Geofence'}
              editEntity={'Collection'}
              editEntityName={selectedCollectionName}
              removeEntity={'Collection'}
              removeEntityName={selectedCollectionName}
              exportValidation={Object.keys(props.geofences || {}).length > 0}
              onSubmitCreate={props.onCreateGeofence}
              onSubmitEdit={props.onEditCollection}
              onSubmitRemove={props.onRemoveCollection}
              onExport={props.onExportCollection}
              selectedCollection={props.selectedCollection}
              selectedGeofence={props.selectedGeofence}
              onClose={props.onCloseGeofenceModal}
            />
          )}
        </ListHeader>
        <ListBody entity="geofences" {...props}>
          {Object.keys(props.collections || {}).length > 0 && (
            <ListButton
              name="Add Geofence"
              classes={props.classes}
              onClick={props.onAddGeofenceClick}
            />
          )}
          <GeofencesBody {...props} />
        </ListBody>
      </div>
      <GeofenceContainer {...props} />
    </div>
  );
};

const ListHeader = (props) => (
  <AppBar position="static" color="default">
    <Toolbar className={props.classes.columnToolBar}>
      <Typography variant="subheading" className={props.classes.columnToolBarTitle}>
        {props.title}
      </Typography>
      {props.children}
    </Toolbar>
  </AppBar>
);

const ListBody = (props) => (
  <div className={props.classes.columnContent}>
    {props[props.entity] ? <List>{props.children}</List> : <Loading {...props} />}
  </div>
);

const ListButton = (props: {name: string; onClick: () => void} & StyledComponentProps) => (
  <ListItem button className={props.classes.button} onClick={props.onClick}>
    <ListItemText disableTypography>
      <Typography variant="button" color="primary">
        {props.name}
      </Typography>
    </ListItemText>
  </ListItem>
);

const CollectionsBody = (props) =>
  Object.keys(props.collections).length > 0 && (
    <div>
      {Object.keys(props.collections).map((key) => {
        const collection = props.collections[key];

        return (
          <ListItem
            key={key}
            role={undefined}
            button
            onClick={props.handleToggle('collection', key)}
            className={key === props.selectedCollection ? props.classes.selectedItem : ''}
          >
            <ListItemText primary={collection.name} />
            {key === props.selectedCollection && (
              <ListItemSecondaryAction style={{right: '1rem'}}>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </div>
  );

const GeofencesBody = (props) =>
  Object.keys(props.geofences).length > 0 && (
    <div>
      {Object.keys(props.geofences).map((key) => {
        const geofence = props.geofences[key];

        return (
          <ListItem
            key={key}
            role={undefined}
            button
            onClick={props.handleToggle('geofence', key)}
            className={key === props.selectedGeofence ? props.classes.selectedItem : ''}
          >
            <ListItemText primary={geofence.name} />
            {key === props.selectedGeofence && (
              <ListItemSecondaryAction style={{right: '1rem'}}>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </div>
  );

const Loading = (props) => (
  <div className={props.classes.loading}>
    <CircularProgress size={24} thickness={5} />
  </div>
);

export const CollectionListView = withStyles(styles)(CollectionListViewComponent);
