import * as React from 'react';
import {ChangeEvent, PureComponent} from 'react';
import turfBbox from '@turf/bbox';
import * as turf from '@turf/helpers';

import {authorize} from 'components/common/hoc';
import {page} from 'components/hoc';
import {environment} from 'config';
import * as mapboxgl from 'mapbox-gl';
import {GeofenceLayer} from 'models';
import {getUrlParams, updateQueryStringParam} from 'utils/url';

import {layers, neighborhoodLayerSubtypes} from './mapData';
import {PoliticalView} from './Political.view';
import {popupTemplates} from './popupTemplates';

interface State {
  layer: GeofenceLayer;
  subtype: string;
}

@authorize({roles: ['admin']})
@page({title: 'Political'})
class PoliticalContainerComponent extends PureComponent<{}, State> {
  state: State;
  private static DEFAULT_SUBTYPE: string = 'NEIGHBORHOOD';
  private map: mapboxgl.Map;
  private popup: mapboxgl.Popup;

  constructor() {
    super();

    (mapboxgl as any).accessToken = environment.settings.mapbox.token;

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [0, -10],
    });

    const {type, subtype} = getUrlParams();

    this.state = {
      layer: type || 'state',
      subtype: subtype || PoliticalContainer.DEFAULT_SUBTYPE,
    };
  }

  componentDidUpdate() {
    this.updateUrl();
  }

  componentDidMount() {
    this.map = this.initMap();
    this.map.addControl(new mapboxgl.NavigationControl());
    this.setupMapEvents();
    this.updateUrl();
  }

  render() {
    const {layer, subtype} = this.state;
    const layerItems = Object.keys(layers).map((key) => ({value: key, text: layers[key].label}));
    const subtypeItems = Object.keys(neighborhoodLayerSubtypes).map((key) => ({
      value: key,
      text: neighborhoodLayerSubtypes[key],
    }));

    const viewProps = {
      clearMapInfo: this.clearMapInfo,
      handleLayerChange: this.handleLayerChange,
      layer,
      subtype,
      layerItems,
      subtypeItems,
      handleSubtypeChange: this.handleSubtypeChange,
      shouldRenderSubtypeFilter: this.shouldRenderSubtypeFilter,
    };

    return <PoliticalView {...viewProps} />;
  }

  handleMapClick = async (e: mapboxgl.MapMouseEvent) => {
    const features = this.map.queryRenderedFeatures(e.point);
    const feature: any = features[0];

    let polygon = null;

    if (feature.geometry.type === 'Polygon') {
      polygon = turf.polygon(feature.geometry.coordinates);
    } else {
      polygon = turf.multiPolygon(feature.geometry.coordinates);
    }

    const boundingBox = turfBbox(polygon);

    this.map.fitBounds(boundingBox as any, {padding: 50});
  };

  handleLayerChange = (newValue: GeofenceLayer) => {
    const layer = newValue as GeofenceLayer;
    this.setState({layer});

    if (layer === 'neighborhood') {
      this.setState({subtype: PoliticalContainer.DEFAULT_SUBTYPE});
    }

    this.loadMapLayer(layer);
  };

  handleSubtypeChange = (newValue: GeofenceLayer) => {
    const subtype = newValue;
    this.setState({subtype});
    this.filterByNeighborhoodSubtype(this.state.layer, subtype);
  };

  handleMouseLeave = () => {
    this.clearMapInfo();
  };

  handleZoomEnd = () => {
    this.setMapMinZoomEqualsLayerMinZoom();
  };

  handleMouseOver = (e: any) => {
    const currentLayer = this.state.layer;

    const features = this.map
      .queryRenderedFeatures(e.point)
      .filter((f: any) => f.layer.id === currentLayer);

    const feature = features[0];

    if (!feature || !(features as any).length) {
      this.clearMapInfo();
      return;
    }

    this.map.getCanvas().style.cursor = 'pointer';
    this.buildPopup(e.lngLat, feature);
  };

  shouldRenderSubtypeFilter = () => {
    return this.state.layer === 'neighborhood';
  };

  initMap() {
    return new mapboxgl.Map({
      container: 'map',
      style: environment.settings.mapbox.urlStyle,
      center: [-98.552193, 38.98672],
      minZoom: 4,
      maxZoom: 18,
      hash: false,
    });
  }

  setupMapEvents() {
    this.map.on('load', () => this.loadMapLayer(this.state.layer));
    this.map.on('click', this.handleMapClick);
    this.map.on('mousemove', this.handleMouseOver);
    this.map.on('mouseleave', this.handleMouseLeave);
    this.map.on('zoomend', this.handleZoomEnd);
  }

  loadMapLayer = (layer: GeofenceLayer) => {
    const previousLayer = this.state.layer;

    if (this.map.getLayer(previousLayer)) {
      this.map.removeLayer(previousLayer);
    } else {
      this.setState({layer});
    }

    this.setMapZoom(layer);

    if (!this.map.getSource(layer)) {
      this.map.addSource(layer, {
        type: 'vector',
        url: layers[layer].url,
      });
    }

    this.map.addLayer(
      {
        id: layer,
        type: 'fill',
        source: layer,
        'source-layer': layers[layer].sourceLayer,
        paint: {
          'fill-outline-color': '#43066d',
          'fill-color': 'rgba(128,7,212,0.02)',
        },
      },
      'place-city-sm',
    );

    if (layer === 'neighborhood') {
      this.filterByNeighborhoodSubtype(layer, this.state.subtype);
    }
  };

  filterByNeighborhoodSubtype = (layer: GeofenceLayer, subtype: string) => {
    this.map.setFilter(layer, ['all', ['==', 'subtype', subtype]]);
  };

  setMapZoom = (layer: GeofenceLayer) => {
    const currentMapZoom = this.map.getZoom();
    const layerZoom = layers[layer].minZoom;

    if (currentMapZoom < layerZoom) {
      this.map.flyTo({zoom: layerZoom});
    } else {
      this.setMapMinZoomEqualsLayerMinZoom(layer);
    }
  };

  setMapMinZoomEqualsLayerMinZoom = (layer: GeofenceLayer = this.state.layer) => {
    const layerZoom = layers[layer].minZoom;
    this.map.setMinZoom(layerZoom);
  };

  buildPopup(popupPosition: mapboxgl.LngLat, feature: any) {
    if (feature.layer.id.includes(this.state.layer)) {
      this.popup.remove();

      return this.popup
        .setLngLat(popupPosition)
        .setHTML(popupTemplates[this.state.layer](feature))
        .addTo(this.map);
    }
  }

  clearMapInfo = () => {
    this.popup.remove();
    this.map.getCanvas().style.cursor = '';
  };

  updateUrl = () => {
    const {layer, subtype} = this.state;

    updateQueryStringParam('type', layer);

    if (layer === 'neighborhood') {
      updateQueryStringParam('subtype', subtype);
    } else {
      updateQueryStringParam('subtype', '');
    }
  };
}

export const PoliticalContainer = PoliticalContainerComponent;
