const commonvars = {
  urls: {},
  apiUrl: {
    geofence: process.env.SERVICE_GEOFENCE_V2_API_URL,
  },
  app: {
    name: process.env.APP_NAME,
    version: process.env.npm_package_version,
    commitHash: (process.env.COMMITHASH || '').slice(0, 8),
  },
  mapbox: {
    token: process.env.MAPBOX_API_KEY,
    urlStyle: process.env.MAPBOX_STYLE_URL,
  },
};

export const environment = {
  currentType: process.env.NODE_ENV || 'development',
  settings: commonvars,
};
