import {CSSProperties} from 'react';
import {StyleRulesCallback} from 'material-ui/styles';

export const styles = (theme) => ({
  typography: {
    margin: '16px 16px 0',
    lineHeight: 1.8,
  } as CSSProperties,
  accountMenuWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  } as CSSProperties,
  accountMenu: {},
  accountMenuAvatar: {
    width: 30,
    height: 30,
  } as CSSProperties,
  accountMenuPopoverBody: {
    width: 180,
  } as CSSProperties,
  buttonWrapper: {
    margin: '5px 9px',
  } as CSSProperties,
  button: {
    fontSize: 11,
  } as CSSProperties,
  versionLabel: {
    margin: 16,
    fontSize: 12,
  } as CSSProperties,
});
