import axios from 'axios';
import {AxiosInstance, AxiosPromise} from 'axios';
import {getToken} from 'bkn-auth';

import {environment} from 'config';
import {GeofenceCollections} from 'models';

import {RestService} from './RestService';

interface Options {
  apiUrl: string;
  baseUrl: string;
  token: string;
}

export class GeofenceCollectionRestService extends RestService<GeofenceCollections> {
  private static instance: GeofenceCollectionRestService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.geofence,
      apiUrl: '/collection',
      token: getToken(),
    });
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new GeofenceCollectionRestService();
    }

    return this.instance;
  }

  public async find(query: {fields: string[]}) {
    const esQuery = {
      size: 100,
      query: {
        bool: {
          must: [
            {
              simple_query_string: {
                query: '*',
                default_operator: 'and',
              },
            },
          ],
        },
      },
      _source: query.fields,
    };

    const res = await this.axiosInstance.post('/search', esQuery);
    return res.data;
  }
}
