import {routerReducer, RouterState} from 'react-router-redux';
import {reducers as bknAuthReducers} from 'bkn-auth';
import {combineReducers} from 'redux';
import {$call} from 'utility-types';

import {app, AppState} from './global/reducers';
import {geofence, GeofenceState} from './modules/geofence';
import {geofenceCollection, GeofenceCollectionState} from './modules/geofenceCollection';

const user = $call(bknAuthReducers.user);
const tokenExpired = $call(bknAuthReducers.tokenExpired);
type bknUser = typeof user;
type bknTokenExpired = typeof tokenExpired;

export type RootState = {
  app: AppState;
  geofence: GeofenceState;
  geofenceCollection: GeofenceCollectionState;
  router: RouterState;
  user: bknUser;
  token: bknUser;
};

export const rootReducer = combineReducers<RootState>({
  app,
  geofence,
  geofenceCollection,
  router: routerReducer,
  ...bknAuthReducers,
});
