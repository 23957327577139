import {CSSProperties} from 'react';
import {StyleRulesCallback} from 'material-ui/styles';

export const styles: StyleRulesCallback = (theme) => ({
  btnMainNavLeftTitle: {
    height: '54px',
    padding: '1rem',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'initial',
    },
  } as CSSProperties,
  btnMainNavLeftTitleItem: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  } as CSSProperties,
  helpCaption: {
    textTransform: 'uppercase',
    fontSize: 10,
    paddingBottom: '0px',
  } as CSSProperties,
  popoverOverwrite: {
    top: 0,
  } as CSSProperties,
});
