import {CSSProperties} from 'react';
import {beakynPurple} from 'utils';

const purple = beakynPurple;

export const styles = (theme) => ({
  header: {
    display: 'flex',
    padding: 20,
    borderWidth: '1px 0px 1px 0px',
  } as CSSProperties,
  subHeader: {
    marginBottom: '1.2rem',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.6)',
  } as CSSProperties,
  dialog: {
    root: {
      height: 600,
      maxHeight: 600,
    },
  } as CSSProperties,
  dialogContent: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    width: 600,
  } as CSSProperties,
  dialogParagraph: {fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)'} as CSSProperties,
});
